<template>
    <div class="card">
        <div class="bg-blue-light p-1 mb-2">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                :title="`${replaceDashWithSpace($route.params.type, 'capitalized')} Report Results`"
                @onClickCloseButton="onClickToClose"
            />
        </div>

        <div class="col-12 px-2 mt-2">

            <ListTable 
                :investigations="investigations"
            />

        </div>

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton.vue';
    import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
    import ListTable from '@/components/molecule/company/hospital/investigation-general/InvestigationGeneralTable.vue';
    import {useRoute, useRouter} from "vue-router";
    import handleHospital from "@/services/modules/hospital";
    import {computed, inject, onMounted, ref} from "vue";
    import Loader from "@/components/atom/LoaderComponent.vue";
    import {useStore} from "vuex";
    import { replaceDashWithSpace,replaceDashWithUnderscore } from '@/services/utils/global';

    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore();

    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const { fetchInvestigationGeneral } = handleHospital();

    const loading = ref(false);
    const investigations = ref([])

    const companyId = computed(() => $route.params.companyId);

    const getQuery = () => {
        let query = '?company_id=' + companyId.value
        if($route.params.type) query += '&investigation_type='+ replaceDashWithUnderscore($route.params.type);
        return query
    };

    const onClickToClose = () => {
        const query = Object.assign({}, $route.query)
        delete query.page

        $router.push({
            name: `pending-sample-${$route.params.type}`,
            params: $route.params,
            query: query
        })
    }

    async function getInvestigationGenerals() {
      loading.value = true;
      const query = getQuery();
      const billId = $route.params.billId;

      try {
        const res = await fetchInvestigationGeneral(query, billId);
        if (!res.status) {
          investigations.value = [];
          return;
        }
        investigations.value = res.data;
      } catch (err) {
        if (err.response) {
          showError(err.response.message)
        }
      } finally {
        loading.value = false;
      }
    }

    onMounted( async () => {
       await getInvestigationGenerals()
    })
</script>