<template>
    <div class="card investigation-card">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th>Bill no</th>
                        <th>Report name</th>
                        <th>Service names</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in investigations" :key="i">
                        <td>{{ item.bill_number }}</td>
                        <td>{{ convertToTitleCase(item.report_name) }}</td>
                        <td>{{ item.service_names }}</td>
                        <td>
                            <router-link :to="{name:'sample-collection-laboratory', query: {bill_number:item.bill_number, barcode:item.id, party_name:item.party_name}}" class="btn btn-dropbox btn-sm me-1">
                                <i class="fa fa-barcode"></i>
                            </router-link>
                            <button @click="onClickToEdit(item)" class="btn btn-primary btn-sm edit-btn">
                                <i class="fas fa-pen"></i>
                            </button>
                            <button v-if="item.is_updated" @click="goToPrint(item)" class="btn btn-secondary btn-sm">
                                <i class="fas fa-print"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p v-if="!investigations.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
    import figureFormatter from "@/services/utils/figureFormatter";
    import handleBarcode from "@/services/modules/barcode";
    import {useRoute, useRouter} from "vue-router";
    import handleHospital from "@/services/modules/hospital";
    import investigationResultPdfPrinter from '@/services/utils/investigationResultPdfPrinter';
    import { inject, onMounted, ref } from "vue";
    import handleCompany from "@/services/modules/company";
    import { useStore } from "vuex";
    import { formatDate, convertToTitleCase, toCapitalizeFirst } from '@/services/utils/global';
    import { replaceDashWithUnderscore } from '@/services/utils/global';

    const props = defineProps({
        investigations: Array,
    })
    const $emit = defineEmits(['onEdit']);
    const {commaFormat} = figureFormatter();
    const { fetchBarcode } = handleBarcode();
    const { dateOfBarthConvertToAge, fetchSaleGeneral } = handleHospital();
    const {fetchCompanyInfo} = handleCompany();
    const { exportToPDF } = investigationResultPdfPrinter();
    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore();
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const investigationDetails = ref({});
    const companyInfo = ref({});
    const barcode = ref('');
    const qrcode = ref('');
    const barcodePatient = ref('');

    const onClickToEdit = (investigationGeneral) => {
        const query = Object.assign({}, $route.query);
        query.investigation_ids = encodeURIComponent(investigationGeneral.investigation_general_ids);
        query.report_type = investigationGeneral.report_name;

        const params = Object.assign({}, $route.params)
        params.saleId = investigationGeneral.sale_master_id

        $router.push({
          name: 'pending-sample-update',
          params: params,
          query: query,
        })
    }
    const getQuery = (investigation) => {
        let query = `?company_id=${$route.params.companyId}`;
        if($route.params.type) query += `&investigation_type=` + replaceDashWithUnderscore($route.params.type);
        query += `&investigation_ids=${investigation.investigation_general_ids}`
        return query;
    }
    const goToPrint = async (investigation) => {

        const query = getQuery(investigation);

        await fetchSaleGeneral(query, investigation.sale_master_id)
            .then((res) =>{
                if (!res.status) return showError(res.message)
                investigationDetails.value = res.data
            })
            .then( () => {
                generatePdf(companyInfo.value, investigationDetails.value, investigation)
            }).catch( (err) => {
                console.log(err);
            })
    }
    const generatePdf = async (company, data, investigation) => {
        const user = $store.state.user.user;
        const isHideFooter = true;
        const isHideHeader = true;

        let qrcodeData = JSON.stringify({
            ref_no: data.id,
            ref_date: data.date,
            created_by: user.name,
            created_date_time: formatDate(new Date())
        })

        const barCodeQuery = `?code=${investigation.investigation_general_ids}&qrcode=${qrcodeData}`;
        const barCodePatientQuery = `?code=${data.contact_profile_id}`;

        await Promise.all([
            fetchBarcode(barCodeQuery).then((res) => {
                barcode.value = res.barcode;
                qrcode.value = res.qrcode;
            }),
            fetchBarcode(barCodePatientQuery).then((res) => {
                barcodePatient.value = res.barcode;
            })
        ]).then(() => {
            exportToPDF(
                company,
                data,
                barcode.value,
                qrcode.value,
                barcodePatient.value,
                user.name,
                convertToTitleCase(investigation.report_name),
                isHideHeader,
                isHideFooter
            )
        }).catch((e) => {
            console.log('Something went wrong');
        })
    }
    const getCompanyInfo = async () => {
        const companyId = $route.params.companyId;

        await fetchCompanyInfo(companyId)
            .then((res) => {
                if(res.status){
                    companyInfo.value = res.data;
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    onMounted( async () => {
        await getCompanyInfo();
    })

</script>

<style scoped>
.edit-btn{
    margin-right: 1rem;
}
.investigation-card{
    min-height: 200px
}
.image-container{
    width: 20px;
}
.avatar-image{
    width: 65px;
}
.patient-detail-th{
    width: 30%;
}
</style>
